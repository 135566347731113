import {
  FETCH_USERS,
  ADD_USERS,
  UPDATE_USERS,
  REMOVE_USERS,
} from './mutation-types'

import service from '@/service'
import config from '@/config'
import axios from 'axios'

const $service = service(axios, config)

export default {
  async applyGetAllUsersSys({ commit }, data) {
    const users = await $service.getAllUsers(data)
    commit(FETCH_USERS, users.data)
  },
  async applyGetIntermediariesSys({ commit }, data) {
    const intermediaryList = await $service.getAllUsers(data)
    return intermediaryList.data;
  },


  async applyGetUserSys({ commit }, data) {
    return await $service.getUser(data)
  },
  async applyAddUserSys({ commit }, data) {
    const user = await $service.addUser(data)
    commit(ADD_USERS, user.data)
  },
  async applyPutUserSys({ commit }, data) {
    const user = await $service.putUser(data)
    commit(UPDATE_USERS, user.data)
  },
  async applyRemoveUserSys({ commit }, data) {
    let promises = []
    let { ids } = data
    ids.forEach(function (id, index) {
      promises.push((async function (id) {

        await $service.removeUser({
          userId: id
        })

        commit(REMOVE_USERS, id)
      })(id))
    })
    await Promise.all(promises)
  },
  async applyAllowUserSys({ commit }, data) {
    const user = await $service.allowUser(data)
    commit(UPDATE_USERS, user.data)
  },
  async applyInitializeUserSys({ commit }, data) {
    return await $service.initializeUser(data)
  },
}
