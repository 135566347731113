import { mixin } from "@/mixins/mixin";

export default {
  allTickets: (state) => {
    return state.ticket
  },
  crrTicket: (state) => {
    return state.crrTicket
  },
}
