import {
  FETCH_MAIL_SENT,
  GET_MAIL_SENT,
  ADD_MAIL_SENT,
  REMOVE_MAIL_SENT
} from './mutation-types'

import service from '@/service'
import config from '@/config'
import axios from 'axios'

const $service = service(axios, config)

export default {
  async applyGetAllMailSent ({ commit }, data) {
    const webmails = await $service.getAllMailSent(data)
    commit(FETCH_MAIL_SENT, webmails.data)
  },
  async applyGetMailSent ({ commit }, data) {
    const webmails = await $service.getMailSent(data)
    commit(GET_MAIL_SENT, webmails.data)
  },
  async applyAddMailSent ({ commit }, data) {
    const mailSent = await $service.addMailSent(data)
    commit(ADD_MAIL_SENT, mailSent.data)
  },
  async applyRemoveMail ({ commit }, data) {
    let promises = []
    let { ids } = data
    ids.forEach(function (id, index) {
      promises.push((async function (id) {

        await $service.removeMailSent({
          mailId: id
        })

        commit(REMOVE_MAIL_SENT, id)
      })(id))
    })
    await Promise.all(promises)
  },
}
