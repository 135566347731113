export const ADD_PROJECT = 'ADD_PROJECT'
export const REMOVE_PROJECT = 'REMOVE_PROJECT'
export const UPDATE_PROJECT = 'UPDATE_PROJECT'
export const FETCH_PROJECT = 'FETCH_PROJECT'
export const GET_PROJECT = 'GET_PROJECT'
export const ENABLE_PROJECT = 'ENABLE_PROJECT'
export const ADD_PROJECT_AVENANT = 'ADD_PROJECT_AVENANT'
export const REMOVE_PROJECT_AVENANT = 'REMOVE_PROJECT_AVENANT'
export const UPDATE_PROJECT_AVENANT = 'UPDATE_PROJECT_AVENANT'
export const ADD_PROJECT_Quittance = 'ADD_PROJECT_Quittance'
export const FETCH_RECEIPT = 'FETCH_RECEIPT'
export const GET_RECEIPT = 'GET_RECEIPT'
export const UPDATE_RECEIPT = 'UPDATE_RECEIPT'
export const UPDATE_PROJECT_SOUSCRIBE = 'UPDATE_PROJECT_SOUSCRIBE'
export const FETCH_PROJECT_DEPTH = 'FETCH_PROJECT_DEPTH'
