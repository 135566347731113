import {
  ADD_PROJECT,
  UPDATE_PROJECT,
  REMOVE_PROJECT,
  FETCH_PROJECT,
  GET_PROJECT,
  FETCH_PROJECT_DEPTH,
  ENABLE_PROJECT,
  ADD_PROJECT_AVENANT,
  UPDATE_PROJECT_AVENANT,
  REMOVE_PROJECT_AVENANT,
  ADD_PROJECT_Quittance, 
  FETCH_RECEIPT,
  GET_RECEIPT,
  UPDATE_RECEIPT,
  UPDATE_PROJECT_SOUSCRIBE
} from './mutation-types'

import service from '@/service'
import config from '@/config'
import axios from 'axios'

const $service = service(axios, config)

export default {
  async applyAllowProject({ commit }, data) {
    return await $service.putCommentProject(data);
  },
  async applyPutComment({ commit }, data) {
    const project = await $service.putCommentProject(data);
    commit(UPDATE_PROJECT, project.data);
  },
  async applyAddComment({ commit }, data) {
    const project = await $service.addCommentProject(data);
    commit(UPDATE_PROJECT, project.data);
  },
  async applyRemoveComment({ commit }, data) {
    let { ids } = data;
    const project = await $service.removeCommentProject({
      commentId: ids[0]
    });
    commit(UPDATE_PROJECT, project.data);
  },
  async applyChangeIntermediary({ commit }, data) {
    const project = await $service.putProject(data)
    commit(UPDATE_PROJECT, project.data)
  },




  /*
  async applyGetAllReceipt({ commit }, data) {
    commit(FETCH_RECEIPT,
      {
        list: [],
        total: 0
      })
    const receipt = await $service.getAllReceipt(data)
    commit(FETCH_RECEIPT, receipt.data)
  },
  async applyGetReceipt({ commit }, data) {
    const receipt = await $service.getReceipt(data);
    commit(GET_RECEIPT, receipt.data);
  },
  async applyPayReceipt({ commit }, data) {
    // const putReceipt = await $service.putReceipt(data);
    // commit(UPDATE_RECEIPT, putReceipt.data);

    let promises = [];
    // let { quittancesData } = data;
    data.forEach(function (el, index) {
      promises.push(
        (async function (el) {
          const putReceipt = await $service.putReceipt({
            ...el
          });
          commit(UPDATE_RECEIPT, putReceipt.data);
        })(el)
      );
    });
    await Promise.all(promises);
  },
  async applyPutReceipt({ commit }, data) {
    const putReceipt = await $service.putReceipt(data);
    commit(UPDATE_RECEIPT, putReceipt.data);
  },

  */





  async applyGetAllProject({ commit }, data) {
    commit(FETCH_PROJECT,
      {
        list: [],
        total: 0
      })
    const projects = await $service.getAllProject(data)
    commit(FETCH_PROJECT, projects.data)
  },
  async applyGetAllProjectDepth({ commit }, data) {
    commit(FETCH_PROJECT_DEPTH,
      {
        list: [],
        total: 0
      })
    const projects = await $service.getAllProject(data)
    commit(FETCH_PROJECT_DEPTH, projects.data)
  },
  async applyGetProject({ commit }, data) {
    const project = await $service.getProject(data);
    commit(GET_PROJECT, project.data);
  },

  async applyPutProject({ commit }, data) {
    const project = await $service.putProject(data);
    let newProject = { ...project.data, differentiate: data.differentiate };
    commit(UPDATE_PROJECT_SOUSCRIBE, newProject);
    return project.data
  },
  async applyEnableProject({ commit }, data) {
    const project = await $service.putProject(data);
    commit(ENABLE_PROJECT, project.data);
    return project.data
  }, 
  async applyAddAvenant({ commit }, data) {
    const project = await $service.addAvenant(data);
    commit(ADD_PROJECT_AVENANT, project.data);
    return project.avenant
  },
  async applyPutAvenant({ commit }, data) {
    const project = await $service.putAvenant(data);
    commit(ADD_PROJECT_AVENANT, project.data);
    return project.avenant
  }, 
  async applyRemoveAvenant({ commit }, data) {
    let promises = [];
    let { ids } = data;
    ids.forEach(function (id, index) {
      promises.push(
        (async function (id) {
          await $service.removeAvenant({
            avenantId: id
          });
          commit(REMOVE_PROJECT_AVENANT, id);
        })(id)
      );
    });
    await Promise.all(promises);
  },
  async applyRemoveProject({ commit }, data) {
    let promises = [];
    let { ids } = data;
    ids.forEach(function (id, index) {
      promises.push(
        (async function (id) {
          await $service.removeProject({
            projectId: id
          });
          commit(REMOVE_PROJECT, id);

        })(id)
      );
    });
    await Promise.all(promises);

  },
  async applyChangeProjectState({ commit }, data) {
    const project = await $service.changeProjectState(data);
    commit(UPDATE_PROJECT, project.data);
  },
  async applyChangeAvenantState({ commit }, data) {
    const project = await $service.changeAvenantState(data);
    commit(UPDATE_PROJECT, project.data);
  },
  async applyPutQuittance({ commit }, data) {
    const project = await $service.putQuittance(data);
    commit(ADD_PROJECT_Quittance, project.data);
    return project.data.quittances
  },
  async applyAddAllQuittance({ commit }, data) {
    const project = await $service.addAllQuittance(data);
    commit(ADD_PROJECT_Quittance, project.data);
    return project.data.quittances
  },
  async applyPutAllQuittances({ commit }, data) {
    return await $service.putAllQuittances(data)
  },
  async applyChangeQuittanceState({ commit }, data) {
    const project = await $service.changeQuittanceState(data);
    commit(UPDATE_PROJECT, project.data);
  },
  async applySentProject({ commit }, data) {
    return await $service.sentProjectMail(data)
  },
}
