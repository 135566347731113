import {
  FETCH_WEB_MAIL,
  ADD_WEB_MAIL,
  UPDATE_WEB_MAIL,
  REMOVE_WEB_MAIL,
} from './mutation-types'

import Vue  from 'vue'

export default {
  [FETCH_WEB_MAIL](state, data) {
    state.webmails = data
  },
  [ADD_WEB_MAIL](state, mail) {
    let webmailsList = [...state.webmails.list]
    webmailsList.unshift(mail)
    state.webmails.list = webmailsList
  },
  [UPDATE_WEB_MAIL](state, mail) {
    const index = state.webmails.list.findIndex((el)=>el.id === mail.id)
    Vue.set(state.webmails.list, index, mail)
  },
  [REMOVE_WEB_MAIL](state, id) {
    const index = state.webmails.list.findIndex((el)=>el.id === id)
    state.webmails.list.splice(index, 1);
  },
}
