import {
  FETCH_DOCUMENTS,
  GET_DOCUMENT,
  ADD_DOCUMENTS,
  UPDATE_DOCUMENTS,
  REMOVE_DOCUMENTS,
} from './mutation-types'

import Vue  from 'vue'

export default {
  [ADD_DOCUMENTS](state, doc) {
    let foldersList = [...state.documents.list]
    foldersList.unshift(doc)
    state.documents.list = foldersList
  },
  [UPDATE_DOCUMENTS](state, doc) {
    const index = state.documents.list.findIndex((el)=>el.id === doc.id)
    state.currentDocument = doc
    Vue.set(state.documents.list, index, doc)
  },
  [GET_DOCUMENT](state, data) {
    state.currentDocument = data
  },
  [FETCH_DOCUMENTS](state, data) {
    state.documents = data
  },
 
  
  [REMOVE_DOCUMENTS](state, id) {
    const index = state.documents.list.findIndex((el)=>el.id === id)
    state.documents.list.splice(index, 1);
  },

  
}
