import {
  FETCH_TICKET,
  ADD_TICKET,
  UPDATE_TICKET,
  GET_TICKET,
  ADD_COMMENT
} from './mutation-types'

import Vue  from 'vue'

export default {
  [FETCH_TICKET](state, data) {
    state.ticket = data
  },
  [ADD_TICKET](state, ticket) {
    let ticketList = [...state.ticket.list]
    ticketList.unshift(ticket)
    state.ticket.list = ticketList
  },
  [ADD_COMMENT](state, comment) {
    state.crrTicket.commentsTicket.push(comment)
  },

  [UPDATE_TICKET](state, ticket) {
    const index = state.ticket.list.findIndex((el)=>el.id === ticket.id)
    state.crrTicket = ticket
    Vue.set(state.ticket.list, index, ticket)
  },
  [GET_TICKET](state, ticket) {
    state.crrTicket = ticket
  }
}
