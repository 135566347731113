export default {
  projects: {
    list: [],
    total: 0
  },

  receipt: {
    list: [],
    total: 0
  },

  currentReceipt: {},

  currentProject: {},

  projectsDepth: {
    list: [],
    total: 0
  },
}
