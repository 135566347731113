import { mixin } from "@/mixins/mixin";

export default {
  allMailSent: (state) => {
    return state.mailSent
  },
  crrMailSent: (state) => {
    return state.crrMailSent
  },
}
