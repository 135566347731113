import Vue from 'vue'
import Router from 'vue-router'
import authGuard from './auth-guard'

Vue.use(Router)

const router = new Router({
  base: "/",
  mode: 'history',
  scrollBehavior() {
    return { x: 0, y: 0 }
  },
  routes: [
    {
      // =============================================================================
      // MAIN LAYOUT ROUTES
      // =============================================================================
      path: '',
      component: () => import('@/layouts/main/Main.vue'),
      children: [
        // =============================================================================
        // Theme Routes
        // =============================================================================
        {
          path: '/',
          name: 'Home',
          component: () => import('@/views/Home.vue'),
          meta: {
            auth: true,
            breadcrumb: [
              { title: 'Accueil', active: true },
            ],
            pageTitle: 'Accueil'
          },
        },
        {
          path: '/settings',
          name: 'Settings',
          component: () => import('@/views/afterAuth/Setting.vue'),
          meta: {
            auth: true,
            breadcrumb: [
              { title: 'Accueil', name: 'Home' },
              { title: 'Paramètres', active: true },
            ],
            pageTitle: 'Paramètres'
          },
        },
        {
          path: '/users',
          name: 'Users',
          component: () => import('@/views/sideBar/User'),
          meta: {
            auth: true,
            breadcrumb: [
              { title: 'Administrateurs', active: true },
            ],
            pageTitle: 'Administrateurs'
          },
        },
        {
          path: '/brokers',
          name: 'Brokers',
          component: () => import('@/views/sideBar/Broker'),
          meta: {
            auth: true,
            breadcrumb: [
              { title: 'Intermédiaires', active: true },
            ],
            pageTitle: 'Intermédiaires'
          },
        },
        {
          path: '/mailing',
          name: 'Mailing',
          component: () => import('@/views/sideBar/Mailing'),
          meta: {
            auth: true,
            breadcrumb: [
              { title: 'Mailing', active: true },
            ],
            pageTitle: 'Mailing'
          },
        },
        {
          path: '/mail-received/:userId/:crrModuleName',
          name: 'MailReceived',
          component: () => import('@/components/aab-vie/aab-vie-mail-received/index.vue'),
          meta: {
            auth: true,
            breadcrumb: [
              { title: 'Accueil', url: '/' },
              { title: 'Mails', active: true },
            ],
            pageTitle: 'Mails'
          },
        },
        //////////////////////////////////////////////////
        {
          path: '/mail-relaunch-current-project',
          name: 'MailRelaunchCurrentProject',
          component: () => import('@/views/sideBar/MailClient/MailRelaunchCurrentProject'),
          meta: {
            auth: true,
            breadcrumb: [
              { title: 'Relance Projet en Cours', active: true },
            ],
            pageTitle: 'Relance Projet en Cours'
          },
        },
        {
          path: '/mail-additional-info',
          name: 'MailAdditionalInfoForIntermediary',
          component: () => import('@/views/sideBar/MailClient/MailAdditionalInfoForIntermediary'),
          meta: {
            auth: true,
            breadcrumb: [
              { title: 'Infos Complémentaires pour Intermédiaire', active: true },
            ],
            pageTitle: 'Infos Complémentaires pour Intermédiaire'
          },
        },
        {
          path: '/mail-default-customers-email',
          name: 'MailDefaultEmailQuoteForCustomers',
          component: () => import('@/views/sideBar/MailClient/MailDefaultEmailQuoteForCustomers'),
          meta: {
            auth: true,
            breadcrumb: [
              { title: 'Devis par Défaut pour Clients', active: true },
            ],
            pageTitle: 'Devis par Défaut pour Clients'
          },
        },
        {
          path: '/mail-default-quote',
          name: 'MailDefaultQuote',
          component: () => import('@/views/sideBar/MailClient/MailDefaultQuote'),
          meta: {
            auth: true,
            breadcrumb: [
              { title: 'Devis par Défaut pour Intermédiaires', active: true },
            ],
            pageTitle: 'Devis par Défaut pour Intermédiaires'
          },
        },
        {
          path: '/mail-subscription',
          name: 'MailSubscription',
          component: () => import('@/views/sideBar/MailClient/MailSubscription'),
          meta: {
            auth: true,
            breadcrumb: [
              { title: 'Souscriptions', active: true },
            ],
            pageTitle: 'Souscriptions'
          },
        },
        {
          path: '/mail-call-contribution',
          name: 'MailCallForContributions',
          component: () => import('@/views/sideBar/MailClient/MailCallForContributions'),
          meta: {
            auth: true,
            breadcrumb: [
              { title: 'Appel de Cotisation', active: true },
            ],
            pageTitle: 'Appel de Cotisation'
          },
        },
        {
          path: '/mail-payement-reminder',
          name: 'MailPaymentReminder',
          component: () => import('@/views/sideBar/MailClient/MailPaymentReminder'),
          meta: {
            auth: true,
            breadcrumb: [
              { title: 'Relance Paiement', active: true },
            ],
            pageTitle: 'Relance Paiement'
          },
        },
        ////////////////////////
        {
          path: '/ongoing-project',
          name: 'OngoingProject',
          component: () => import('@/views/sideBar/OngoingProject/index.vue'),
          meta: {
            auth: true,
            breadcrumb: [
              { title: 'Projets en cours', active: true },
            ],
            pageTitle: 'Projets en cours'
          },
        },
        {
          path: '/subscribe-abq',
          name: 'ABQ',
          component: () => import('@/views/sideBar/Suscribe/ABQ/index.vue'),
          meta: {
            auth: true,
            breadcrumb: [
              { title: 'Souscriptions ABQ', active: true },
            ],
            pageTitle: 'Souscriptions ABQ'
          },
        },
        {
          path: '/subscribe-apf',
          name: 'APF',
          component: () => import('@/views/sideBar/Suscribe/APF/index.vue'),
          meta: {
            auth: true,
            breadcrumb: [
              { title: 'Souscriptions APF', active: true },
            ],
            pageTitle: 'Souscriptions APF'
          },
        },
        {
          path: '/subscribe-aaf',
          name: 'AAF',
          component: () => import('@/views/sideBar/Suscribe/AAF/index.vue'),
          meta: {
            auth: true,
            breadcrumb: [
              { title: 'Souscriptions AAF', active: true },
            ],
            pageTitle: 'Souscriptions AAF'
          },
        },
        {
          path: '/subscribe-aae',
          name: 'AAE',
          component: () => import('@/views/sideBar/Suscribe/AAE/index.vue'),
          meta: {
            auth: true,
            breadcrumb: [
              { title: 'Souscriptions AAE', active: true },
            ],
            pageTitle: 'Souscriptions AAE'
          },
        },
        {
          path: '/subscribe-ahr',
          name: 'AHR',
          component: () => import('@/views/sideBar/Suscribe/AHR/index.vue'),
          meta: {
            auth: true,
            breadcrumb: [
              { title: 'Souscriptions AHR', active: true },
            ],
            pageTitle: 'Souscriptions AHR'
          },
        },
        {
          path: '/ticket',
          name: 'Ticket',
          component: () => import('@/views/sideBar/Ticket/index.vue'),
          meta: {
            auth: true,
            breadcrumb: [
              { title: 'Tickets', active: true },
            ],
            pageTitle: 'Tickets'
          },
        },
        {
          path: '/ticket/:ticketId',
          name: 'Comment',
          component: () => import('@/views/sideBar/Ticket/comment/index.vue'),
          meta: {
            auth: true,
            breadcrumb: [
              { title: 'Ticket', active: true },
            ],
            pageTitle: 'Ticket'
          },
        },
        {
          path: '/profil',
          name: 'Profil',
          component: () => import('@/views/afterAuth/Profil.vue'),
          meta: {
            auth: true,
            breadcrumb: [
              { title: 'Accueil', name: 'Home' },
              { title: 'Mon Profil', active: true },
            ],
            pageTitle: 'Mon Profil'
          },
        },
        {
          path: '/policy',
          name: 'Policy',
          component: () => import('@/views/Policy.vue'),
          meta: {
            auth: true,
            breadcrumb: [
              { title: 'Politiques juridiques', active: true },
            ],
            pageTitle: 'Politiques juridiques'
          },
        },
        {
          path: '/slips',
          name: 'Slips',
          component: () => import('@/views/sideBar/Bordereaux/index.vue'),
          meta: {
            auth: true,
            breadcrumb: [
              { title: 'Bordereaux de commission', active: true },
            ],
            pageTitle: 'Bordereaux de commission'
          },
        },
        {
          path: '/customer-document',
          name: 'CustomerDocument',
          component: () => import('@/views/sideBar/DocClient/index.vue'),
          meta: {
            auth: true,
            breadcrumb: [
              { title: 'Documents clients', active: true },
            ],
            pageTitle: 'Documents clients'
          },
        },
        {
          path: '/customer-account',
          name: 'CustomerAccount',
          component: () => import('@/views/sideBar/CustomerAccount/index.vue'),
          meta: {
            auth: true,
            breadcrumb: [
              { title: 'Comptes clients', active: true },
            ],
            pageTitle: 'Comptes clients'
          },
        }
      ],
    },
    // =============================================================================
    // FULL PAGE LAYOUTS
    // =============================================================================
    {
      path: '',
      component: () => import('@/layouts/full-page/FullPage.vue'),
      children: [
        // =============================================================================
        // PAGES
        // =============================================================================
        {
          path: '/login',
          name: 'Login',
          component: () => import('@/views/beforeAuth/Login.vue'),
        },
        {
          path: '/forget-password',
          name: 'ForgetPassword',
          component: () => import('@/views/beforeAuth/ForgetPassword.vue'),
        },
        {
          path: '/error-404',
          name: 'Error404',
          component: () => import('@/views/beforeAuth/Error404.vue'),
        },
        {
          path: '/privacy-and-security',
          name: 'PrivacyAndSecurity',
          component: () => import('@/views/static/PrivacyAndSecurity.vue'),
        }
      ]
    },
    // Redirect to 404 page, if no match found
    {
      path: '*',
      redirect: '/error-404'
    }
  ],
})


router.beforeEach(authGuard)
router.afterEach(() => {
  // Remove initial loading
  const appLoading = document.getElementById('loading-bg')
  if (appLoading) {
    appLoading.style.display = "none";
  }
})
Vue.router = router

export default router
