import {
  FETCH_WEB_MAIL,
  ADD_WEB_MAIL,
  UPDATE_WEB_MAIL,
  REMOVE_WEB_MAIL,
} from './mutation-types'

import service from '@/service'
import config from '@/config'
import axios from 'axios'

const $service = service(axios, config)

export default {
  async applyGetAllWebMail ({ commit }, data) {
    const webmails = await $service.getAllMailing(data)
    commit(FETCH_WEB_MAIL, webmails.data)
  },
  async applyGetWebMail ({commit}, data) {
    return await $service.getMailing(data)
  },
  async applyAddWebMail ({ commit }, data) {
    const webMail = await $service.addMailing(data)
    commit(ADD_WEB_MAIL, webMail.data)
  },
  async applySendWebMail ({ commit }, data) {
    return await $service.sendMailing(data)
    // commit(ADD_WEB_MAIL, webMail.data)
  },
  async applyPutWebMail ({ commit }, data) {
    const webMail = await $service.putMailing(data)
    commit(UPDATE_WEB_MAIL, webMail.data)
  },
  async applyRemoveWebMail ({ commit }, data) {
    let promises = []
    let { ids } = data
    ids.forEach(function (id, index) {
      promises.push((async function (id) {

        await $service.removeMailing({
          mailingId: id
        })

        commit(REMOVE_WEB_MAIL, id)
      })(id))
    })
    await Promise.all(promises)
  },
}
