// import { mixin } from "@/mixins/mixin";

import { mixin } from "@/mixins/mixin";

export default {
  allProjects: (state) => {
    return state.projects
  },

  allProjectsDepth: (state) => {
    return state.projectsDepth
  },

  currentSuscribeData: (state) => {
    if (state.currentProject.avenants && state.currentProject.avenants.length > 0) {
      let { avenants } = state.currentProject

      let listSort = []
      avenants.forEach(element => {
        listSort.push({ ...element })
      });
      avenants = mixin.methods.filterJson(listSort, "createdAt");

      return { ...state.currentProject, avenants: avenants }
    } else {
      return state.currentProject
    }
  },

  allReceipt: (state) => {
    return state.receipt
  },

  getSupInfoObject() {
    return {
      fullInfo: "{{Civilité}} {{Nom}}, demande d’informations complémentaires",
      medicalVisit: "{{Civilité}} {{Nom}}, votre adhésion ({{Ref_Projet}}) est soumise à visite médicale",
      rejection: "{{Civilité}} {{Nom}}, suite votre demande d’adhésion ({{Ref_Projet}})",
      standardValidation: "{{Civilité}} {{Nom}}, suite votre demande d’adhésion ({{Ref_Projet}})",
      validationWithExtraPremium: "{{Civilité}} {{Nom}}, suite votre demande d’adhésion ({{Ref_Projet}})"
    }
  },
  currentReceiptData: (state) => {
    return state.currentReceipt
  },
}
