import { mixin } from "@/mixins/mixin";

export default {

  allDocuments: (state) => {
    if (state.documents.list.length > 0) {
      let { list, total } = state.documents
      let listSort = [...list];
      list = mixin.methods.filterJsonTableByLevel(listSort, "name", "", "");
      return { list, total }
    } else {
      return state.documents
    }
  },

  currentDocumentData: (state) => {
    return state.currentDocument
  }

}
