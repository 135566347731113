import {
  UPDATE_PROJECT,
  ADD_PROJECT,
  REMOVE_PROJECT,
  FETCH_PROJECT,
  GET_PROJECT,
  ENABLE_PROJECT,
  ADD_PROJECT_AVENANT,
  UPDATE_PROJECT_AVENANT,
  REMOVE_PROJECT_AVENANT,
  ADD_PROJECT_Quittance,
  FETCH_RECEIPT,
  GET_RECEIPT,
  UPDATE_RECEIPT,
  UPDATE_PROJECT_SOUSCRIBE,
  FETCH_PROJECT_DEPTH
} from './mutation-types'

import Vue from 'vue'

export default {
  [ADD_PROJECT](state, project) {
    let projetList = [...state.projects.list]
    projetList.unshift(project)
    state.projects.list = projetList
  },

  [FETCH_RECEIPT](state, receipt) {
    state.receipt = receipt
  },

  [GET_RECEIPT](state, data) {
    state.currentReceipt = data
  },

  [UPDATE_RECEIPT](state, receipt) {
    const index = state.receipt.list.findIndex((el) => el.id === receipt.id)
    state.currentReceipt = receipt
    Vue.set(state.receipt.list, index, receipt)
  },

  [FETCH_PROJECT](state, project) {
    state.projects = project
  },

  [GET_PROJECT](state, data) {
    state.currentProject = data
  },

  [FETCH_PROJECT_DEPTH](state, data) {
    state.projectsDepth = data
  },

  [UPDATE_PROJECT](state, project) {
    const index = state.projects.list.findIndex((el) => el.id === project.id)
    state.currentProject = project
    Vue.set(state.projects.list, index, project)
  },

  [UPDATE_PROJECT_SOUSCRIBE](state, project) {
    const index = state.projects.list.findIndex((el) => el.id === project.id)
    state.currentProject = project
    Vue.set(state.projects.list, index, project)

    if (project.differentiate == "sendSubscriptionNotice") {
      if (project.notice == "standardValidation" || project.notice == "validationWithExtraPremium" || project.notice == "rejection") {
        state.projects.list.splice(index, 1)
      }
    }
  },

  [ENABLE_PROJECT](state, project) {
    const index = state.projects.list.findIndex((el) => el.id === project.id)
    state.currentProject = project
    Vue.set(state.projects.list, index, project)
    state.projects.total -= 1;
    state.projects.list.splice(index, 1);
  },

  [ADD_PROJECT_AVENANT](state, project) {
    const index = state.projects.list.findIndex((el) => el.id === project.id)
    state.currentProject = project
    Vue.set(state.projects.list, index, project)
  },

  [ADD_PROJECT_Quittance](state, project) {
    const index = state.projects.list.findIndex((el) => el.id === project.id)
    state.currentProject = project
    Vue.set(state.projects.list, index, project)
  },

  [REMOVE_PROJECT](state, id) {
    const index = state.projects.list.findIndex((el) => el.id === id)
    state.projects.list.splice(index, 1);
  },

  [REMOVE_PROJECT_AVENANT](state, id) {
    const index = state.currentProject.avenants.findIndex((el) => el.id === id)
    state.currentProject.avenants.splice(index, 1);
    if (state.currentProject.avenants == 0) {
      const idxProj = state.projects.list.findIndex((el) => el.id === id)
      state.projects.list.splice(idxProj, 1);
      state.projects.total -= 1;
    }
  },
}
