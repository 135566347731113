/* eslint-disable no-console */
/* eslint-disable no-unused-vars */
import { AUTH_TOKEN_KEY } from "@/constants";

export default ($http, $config) => {
  const $api = $http.create({
    baseURL: $config.serverURL,
    headers: { "Content-Type": "application/json" }
  });

  $api.interceptors.request.use(
    config => {
      const authToken = sessionStorage.getItem(AUTH_TOKEN_KEY);
      if (authToken) {
        //config.headers["Access-Token"] = sessionStorage.getItem(AUTH_TOKEN_KEY);
        config.headers["Authorization"] = `Bearer ${authToken}`;
      }
      return config;
    },
    error => {
      return Promise.reject(error);
    }
  )

  /**
   * Upload File
   * @param {*} data
   */
  const uploadFile = data => {
    return $api.post("/api/v1/upload-file", data).then(res => res.data);
  };

  /**
   * Login user
   * @param {*} data
   */
  const signin = data => {
    return $api.post('/api/v1/auth/signin', data)
      .then(res => res.data)
  }

  /**
   * Forget password
   * @param {*} data
   */
  const forgetPassword = data => {
    return $api.post('/api/v1/auth/forget-pwd', data)
      .then(res => res.data)
  }

  /**
   * Change password
   * @param {*} data
   */
  const changePassword = data => {
    return $api.post('/api/v1/auth/change-pwd', data)
      .then(res => res.data)
  }

  /**
   * Allow (Active/Inactive) user
   * @param {*} data
   */ 
  const allowUser = data => {
    return $api.post('/api/v1/users/allow', data)
      .then(res => res.data)
  }

  /**
   * Init password user
   * @param {*} data
   */
  const initializeUser = data => {
    return $api.post('/api/v1/users/initialize', data)
      .then(res => res.data)
  }

  /**
   * Add user
   * @param {*} data
   */
  const addUser = data => {
    return $api.post('/api/v1/users/add', data)
      .then(res => res.data)
  }

  /**
   * Update user
   * @param {*} data
   */
  const putUser = data => {
    return $api.post('/api/v1/users/put', data)
      .then(res => res.data)
  }

  /**
   * Get User
   * @param {*} data
   */
  const getUser = (params) => {
    return $api
      .get('/api/v1/users/item', {
        params
      })
      .then(res => res.data);
  }

  /**
   * Get All Users
   * @param {*} data
   */
  const getAllUsers = (params) => {
    return $api
      .get("/api/v1/users/list", {
        params
      })
      .then(res => res.data);
  }

  /**
   * Remove user
   * @param {*} data
   */
  const removeUser = data => {
    return $api.post('/api/v1/users/remove', data)
      .then(res => res.data)
  }


  /**
 * verify Mail
 * @param {*} data
 */
  const checkToken = data => {
    return $api.post('/api/v1/auth/check-email', data)
      .then(res => res.data)
  }

  /**
* resent Mail
* @param {*} data
*/
  const resentMail = data => {
    return $api.post('/api/v1/auth/send-again-email', data)
      .then(res => res.data)
  }


  /**
     * Add setting
     * @param {*} data
     */
  const addSetting = data => {
    return $api.post('/api/v1/setting/add', data)
      .then(res => res.data)
  }

  /**
   * Update setting
   * @param {*} data
   */
  const putSetting = data => {
    return $api.post('/api/v1/setting/put', data)
      .then(res => res.data)
  }

  /**
   * Remove setting
   * @param {*} data
   */
  const removeSetting = data => {
    return $api.post('/api/v1/setting/remove', data)
      .then(res => res.data)
  }

  /**
   * setting
   * @param {*} data
   */
  const getSetting = (params) => {
    return $api
      .get('/api/v1/setting/item', {
        params
      })
      .then(res => res.data);
  }




  /**
     * Add mailing
     * @param {*} data
     */
  const sendMailing = data => {
    return $api.post('/api/v1/mailing/direct-send', data)
      .then(res => res.data)
  }

  /**
   * Add mailing
   * @param {*} data
   */
  const addMailing = data => {
    return $api.post('/api/v1/mailing/add', data)
      .then(res => res.data)
  }

  /**
   * Update mailing
   * @param {*} data
   */
  const putMailing = data => {
    return $api.post('/api/v1/mailing/put', data)
      .then(res => res.data)
  }

  /**
   * Remove mailing
   * @param {*} data
   */
  const removeMailing = data => {
    return $api.post('/api/v1/mailing/remove', data)
      .then(res => res.data)
  }

  /**
   * mailing
   * @param {*} data
   */
  const getMailing = (params) => {
    return $api
      .get('/api/v1/mailing/item', {
        params
      })
      .then(res => res.data);
  }

  /**
  * mailing
  * @param {*} data
  */
  const getAllMailing = (params) => {
    return $api
      .get('/api/v1/mailing/list', {
        params
      })
      .then(res => res.data);
  }

  /**
   * Add mail sent
   * @param {*} data
   */
  const addMailSent = data => {
    return $api.post('/api/v1/mail-send/add', data)
      .then(res => res.data)
  }

  /**
   * GET all mail sent
   * @param {*} data
   */
  const getAllMailSent = (params) => {
    return $api
      .get('/api/v1/mail-receive/list', {
        params
      })
      .then(res => res.data);
  }

  /**
   * GET  mail sent item
   * @param {*} data
   */
  const getMailSent = (params) => {
    return $api
      .get('/api/v1/mail-send/item', {
        params
      })
      .then(res => res.data);
  }

  /**
  * Remove mailing
  * @param {*} data
  */
  const removeMailSent = data => {
    return $api.post('/api/v1/mail-receive/remove', data)
      .then(res => res.data)
  }

  /**
   * Add Slips
    @param{RARAMS (name, filesname, company, type)}
   */
  const addBordereau = data => {
    return $api.post("/api/v1/bordereau/add", data).then(res => res.data)
  };

  /**
   * Remove Slips
   * @param {PARAMS(slipId)}
   */
  const removeBordereau = data => {
    return $api.post("/api/v1/bordereau/remove", data).then(res => res.data)
  };

  /**
   * Update Sliper
   * @param {PARAMS(name, filesname, company, type)}
   */
  const putBordereau = data => {
    return $api.post("/api/v1/bordereau/put", data).then(res => res.data);
  };

  /**
   *
   * Get All slips
   */
  const getAllBordereau = params => {
    return $api
      .get("/api/v1/bordereau/list", {
        params
      }).then(res => res.data);
  };

  /**
   * @param{PARAMS(bordereauId) }
   * Get slip
   */

  const getBordereau = params => {
    return $api
      .get("/api/v1/bordereau/item", {
        params
      })
      .then(res => res.data);
  };

  /**
   * @param{*}
   * Add document
   */

  const addDocument = data => {
    return $api.post("/api/v1/doc-client/add", data).then(res => res.data)
  };

  /**
   * @param{*}
   * Update document
   */

  const putDocument = data => {
    return $api.post("/api/v1/doc-client/put", data).then(res => res.data);
  };
  /**
   * @param{*}
   * remove document
   */

  /**
   * remove ticket
   * @param {*} data
   */
  const removeDocument = data => {
    return $api.post('/api/v1/doc-client/remove', data)
      .then(res => res.data)
  }

  /**
   * @param{}
   * All document
   */

  const getAllDocument = (params) => {
    return $api.get('/api/v1/doc-client/list', { params }).then(res => res.data);
  }

  /**
   * @param{*}
   * All document
   */

  const getDocument = params => {
    return $api
      .get("/api/v1/doc-client/item", {
        params
      })
      .then(res => res.data);
  };


  /**
   * Add ticket
   * @param {*} data
   */
  const addTicket = data => {
    return $api.post('/api/v1/ticket/add', data)
      .then(res => res.data)
  }

  /**
   * Update ticket
   * @param {*} data
   */
  const putTicket = data => {
    return $api.post('/api/v1/ticket/put', data)
      .then(res => res.data)
  }

  /**
   * change ticket state
   * @param {*} data
   */
  const changeTicketStatus = data => {
    return $api.post('/api/v1/ticket/allow', data)
      .then(res => res.data)
  }

  /**
   * remove ticket
   * @param {*} data
   */
  const removeTicket = data => {
    return $api.post('/api/v1/ticket/remove', data)
      .then(res => res.data)
  }

  /**
   * ticket
   * @param {*} data
   */
  const getTicket = (params) => {
    return $api
      .get('/api/v1/ticket/item', {
        params
      })
      .then(res => res.data);
  }

  /**
   * allTicket
   * @param {*} data
   */
  const getAllTicket = (params) => {
    return $api
      .get('/api/v1/ticket/list', {
        params
      })
      .then(res => res.data);
  }


  /**
   * Add comment
   * @param {*} data
   */
  const addCommentTicket = data => {
    return $api.post('/api/v1/ticket/comment/add', data)
      .then(res => res.data)
  }

  /**
   * Update comment
   * @param {*} data
   */
  const putCommentTicket = data => {
    return $api.post('/api/v1/ticket/comment/put', data)
      .then(res => res.data)
  }

  /**
   * remove comment
   * @param {*} data
   */
  const removeCommentTicket = data => {
    return $api.post('/api/v1/ticket/comment/remove', data)
      .then(res => res.data)
  }

  /**
   * Add suscribe
   * @param {*} data
   */
  const addSuscribeOffLine = data => {
    return $api.post("/api/v1/project/add/offline", data).then(res => res.data)
  };
  const addSimulation = data => {
    return $api.post("/api/v1/project/simulation", data).then(res => res.data)
  };

  /**
   * allNotif
   * @param {*} data
   */
  const getAllNotification = (params) => {
    return $api
      .get('/api/v1/notification/list', {
        params
      })
      .then(res => res.data);
  };
  /**
  * read notificatio
  * @param {*} data
  */
  const readNotification = data => {
    return $api.post('/api/v1/notification/view', data)
      .then(res => res.data)
  };


  /**
   * allSuscribe
   * @param {*} data
   */
  const getAllProject = (params) => {
    return $api
      .get('/api/v1/project/list', {
        params
      })
      .then(res => res.data);
  };

  /**
   * Change avenant paymentStatus
    @param {} data
   */
  const changeAvenantState = data => {
    return $api.post('/api/v1/project/avenant/allow', data)
      .then(res => res.data)
  };
  /**
 * allRECEIPT
 * @param {*} data
 */
  const getAllReceipt = (params) => {
    return $api
      .get('/api/v1/project/quittance/list', {
        params
      })
      .then(res => res.data);
  };

  /**
 * RECEIPT
 * @param {*} data
 */
  const getReceipt = (params) => {
    return $api
      .get('/api/v1/project/quittance/item', {
        params
      })
      .then(res => res.data);
  };

  /**
   * Update RECEIPT
   * @param {*} data
   */
  const putReceipt = data => {
    return $api.post('/api/v1/project/quittance/put', data)
      .then(res => res.data)
  }

  const putQuittance = data => {
    return $api.post('/api/v1/project/quittance/put', data)
      .then(res => res.data)
  }

  const putAllQuittances = data => {
    return $api.post('/api/v1/project/quittance/put/all', data)
      .then(res => res.data)
  }

  const addAllQuittance = data => {
    return $api.post('/api/v1/project/quittance/add/all', data)
      .then(res => res.data)
  }

  const changeQuittanceState = data => {
    return $api.post('/api/v1/project/quittance/allow', data)
      .then(res => res.data)
  };

  /**
 * Suscribe
 * @param {*} data
 */
  const getProject = (params) => {
    return $api
      .get('/api/v1/project/item', {
        params
      })
      .then(res => res.data);
  };


  /**
   * Change project State
   * @param {*} data
   */
  const changeProjectState = data => {
    return $api.post('/api/v1/project/allow', data)
      .then(res => res.data)
  };

  /**
   * Update project
   * @param {*} data
   */
  const putProject = data => {
    return $api.post('/api/v1/project/put', data)
      .then(res => res.data)
  };

  /**
   * remove project
   * @param {*} data
   */
  const removeProject = data => {
    return $api.post('/api/v1/project/remove', data)
      .then(res => res.data)
  };

  /**
   * SEND MAIL
   * @param {*} data
   */
  const sentProjectMail = data => {
    return $api.post('/api/v1/project/send/mail', data)
      .then(res => res.data)
  };

  const addCommentProject = data => {
    return $api.post('/api/v1/project/comment/add', data)
      .then(res => res.data)
  };
  const putCommentProject = data => {
    return $api.post('/api/v1/project/comment/put', data)
      .then(res => res.data)
  };

  const removeCommentProject = data => {
    return $api.post('/api/v1/project/comment/remove', data)
      .then(res => res.data)
  };

  const addAvenant = data => {
    return $api.post('/api/v1/project/avenant/add', data)
      .then(res => res.data)
  };

  const putAvenant = data => {
    return $api.post('/api/v1/project/avenant/put', data)
      .then(res => res.data)
  };

  const removeAvenant = data => {
    return $api.post('/api/v1/project/avenant/remove', data)
      .then(res => res.data)
  };

  /**
  *  parrainStats
  * @param {userId, parentCode} data
  */
  const parrainStats = data => {
    return $api.post("/api/v1/users/parrain/stats", data).then(res => res.data);
  };

  const generateCertificat = data => {
    return $api.post('/api/v1/pdf/generate/no-save/certificat', data)
      .then(res => res.data)
  }

  const generateCotisation = data => {
    return $api.post('/api/v1/pdf/generate/no-save/cotisation', data)
      .then(res => res.data)
  }

  const generateCotisationSolde = data => {
    return $api.post('/api/v1/pdf/generate/no-save/cotisation/solde', data)
      .then(res => res.data)
  }

  /**
   * @param{}
   * All document
   */
  const getItemDocument = (params) => {
    return $api.get('/api/v1/doc-client/item', { params }).then(res => res.data);
  }

  return {
    uploadFile,
    signin,
    forgetPassword,
    changePassword,
    changeAvenantState,
    changeQuittanceState,
    allowUser, initializeUser, addUser, putUser, removeUser, getUser, getAllUsers,
    addSetting, putSetting, removeSetting, getSetting,
    sendMailing, addMailing, putMailing, removeMailing, getMailing, getAllMailing,
    addMailSent, getAllMailSent, getMailSent, removeMailSent,
    addTicket, putTicket, getAllTicket, getTicket, removeTicket, changeTicketStatus,
    addCommentTicket, putCommentTicket, removeCommentTicket, addCommentProject, putCommentProject, removeCommentProject,
    addBordereau, getBordereau, getAllBordereau, putBordereau, removeBordereau,
    addDocument, putDocument, getAllDocument, removeDocument, getDocument,
    getAllNotification, readNotification,
    addSuscribeOffLine, addSimulation,
    getAllReceipt, getReceipt, putReceipt,
    putQuittance, putAllQuittances, addAllQuittance,
    getAllProject, putProject, changeProjectState, removeProject, sentProjectMail, getProject,
    addAvenant, putAvenant, removeAvenant, parrainStats,
    generateCotisationSolde, generateCotisation, generateCertificat,
    getItemDocument,
  }
}
