import {
  FETCH_SLIPS,
  ADD_SLIPS,
  UPDATE_SLIPS,
  REMOVE_SLIPS,
  GET_SLIP,
} from './mutation-types'

import Vue  from 'vue'

export default {
  [GET_SLIP](state, data) {
    state.currentSlip = data
  },
  [FETCH_SLIPS](state, data) {
    state.slips = data
  },
  [ADD_SLIPS](state, slip) {
    let slipsList = [...state.slips.list]
    slipsList.unshift(slip)
    state.slips.list = slipsList
  },
  [UPDATE_SLIPS](state, slip) {
    const index = state.slips.list.findIndex((el)=>el.id === slip.id)
    state.currentSlip = slip
    Vue.set(state.slips.list, index, slip)
  },
  [REMOVE_SLIPS](state, id) {
    const index = state.slips.list.findIndex((el)=>el.id === id)
    state.slips.list.splice(index, 1);
  }
}
