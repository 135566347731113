


import {
  FETCH_SLIPS,
  GET_SLIP,
  ADD_SLIPS,
  UPDATE_SLIPS,
  REMOVE_SLIPS,

} from './mutation-types'

import service from '@/service'
import config from '@/config'
import axios from 'axios'

const $service = service(axios, config)


export default {

  async applyAddSlip ({ commit }, data) {
    const item = await $service.addBordereau(data)
   commit(ADD_SLIPS, item.data)
 },
  async applyGetAllSlips ({ commit }, data) {
     const items = await $service.getAllBordereau(data)
    commit(FETCH_SLIPS, items.data)
  },
  async applyGetSlip ({commit}, data) {
     const item = await $service.getBordereau(data)
    commit(GET_SLIP, item.data)
  },
  async applyPutSlip ({ commit }, data) {
     const item = await $service.putBordereau(data)
    commit(UPDATE_SLIPS, item.data)
  },
  async applyRemoveSlip ({ commit }, data) {
    let promises = []
    let { bordereauId } = data
    bordereauId.forEach(function (id, index) {
      promises.push((async function (id) {
         await $service.removeBordereau({
          bordereauId : id,
          crrModel : data.crrModel,
          itemId : data.itemId
         })
        commit(REMOVE_SLIPS, id)
      })(id))
    })
    await Promise.all(promises)
  },
}

 