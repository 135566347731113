/*=========================================================================================
  File Name: store.js
  Description: Vuex store
  ----------------------------------------------------------------------------------------
  Item Name: Vuexy - Vuejs, HTML & Laravel Admin Dashboard Template
  Author: Pixinvent
  Author URL: http://www.themeforest.net/user/pixinvent
==========================================================================================*/


import Vue from 'vue'
import Vuex from 'vuex'

import state from "./state"
import getters from "./getters"
import mutations from "./mutations"
import actions from "./actions"

import auth from './modules/auth'
import general from './modules/general'
import users from './modules/users'
import ticket from './modules/ticket'
import mailing from './modules/mailing'
import emailSent from './modules/emailSent'
import slips from './modules/slips'
import customerDocument from './modules/customerDocument'
import suscribe from './modules/suscribe'

Vue.use(Vuex)

export default new Vuex.Store({
    getters,
    mutations,
    state,
    actions,
    strict: false,//process.env.NODE_ENV !== 'production',
    modules: { 
      auth,
      general,
      users,
      mailing,
      emailSent,
      ticket,
      slips,
      customerDocument,
      suscribe,
    }
})
