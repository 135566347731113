import {
  FETCH_TICKET,
  GET_TICKET,
  ADD_TICKET,
  ADD_COMMENT,
  UPDATE_TICKET,
} from './mutation-types'

import service from '@/service'
import config from '@/config'
import axios from 'axios'

const $service = service(axios, config)

export default {
  async applyGetAllTicket ({ commit }, data) {
    const ticket = await $service.getAllTicket(data)
    commit(FETCH_TICKET, ticket.data)
  },
  async applyGetTicket ({commit}, data) {
    const ticket = await $service.getTicket(data)
    commit(GET_TICKET, ticket.data)
  },
  async applyAddTicket ({ commit }, data) {
    const ticket = await $service.addTicketTicket(data)
    commit(ADD_TICKET, ticket.data)
  },
  async applyAddComment ({ commit }, data) {
    const ticket = await $service.addCommentTicket(data)
    commit(UPDATE_TICKET, ticket.data)
  },
  async applyPutComment ({ commit }, data) {
    const ticket = await $service.putCommentTicket(data)
    commit(UPDATE_TICKET, ticket.data)
  },
  async applyPutTicket ({ commit }, data) {
    const ticket = await $service.changeTicketStatus(data)
    commit(UPDATE_TICKET, ticket.data)
  },
  async applyRemoveComment({ commit }, data) {
    let { ids } = data;
    const ticket = await $service.removeCommentTicket({
      commentId: ids[0]
    });
    commit(UPDATE_TICKET, ticket.data);
  },
}
