import {
  FETCH_DOCUMENTS,
  GET_DOCUMENT,
  ADD_DOCUMENTS,
  UPDATE_DOCUMENTS,
  REMOVE_DOCUMENTS,

  
} from './mutation-types'

import service from '@/service'
import config from '@/config'
import axios from 'axios'

const $service = service(axios, config)


 

export default {

  async applyAddDocument({ commit }, data) {
    const item = await $service.addDocument(data)
    commit(ADD_DOCUMENTS, item.data)
  },
  async applyPutDocument({ commit }, data) {
    const item = await $service.putDocument(data)
    commit(UPDATE_DOCUMENTS, item.data)

  },
  async applyGetAllDocuments({ commit }, data) {
    const items = await $service.getAllDocument(data)
    commit(FETCH_DOCUMENTS, items.data)
  },

  async applyGetDocument({ commit }, data) {
    const item = await $service.getDocument(data)
    commit(GET_DOCUMENT, item.data)
  },

  async applyRemoveDocument({ commit }, data) {

    let promises = []
    let { docId } = data
    docId.forEach(function (id, index) {
      promises.push((async function (id) {

        await $service.removeDocument({
          docId: id
        })
        commit(REMOVE_DOCUMENTS, id)
      })(id))
    })
    await Promise.all(promises)


  },

  

}
