import {
  FETCH_MAIL_SENT,
  ADD_MAIL_SENT,
  GET_MAIL_SENT,
  REMOVE_MAIL_SENT
} from './mutation-types'

export default {
  [FETCH_MAIL_SENT](state, data) {
    state.mailSent = data
  },
  [GET_MAIL_SENT](state, data) {
    state.crrMailSent = data
  },
  [ADD_MAIL_SENT](state, mail) {
    let mailSentList = [...state.mailSent.list]
    mailSentList.unshift(mail)
    state.mailSent.list = mailSentList
  },
  [REMOVE_MAIL_SENT](state, id) {
    const index = state.mailSent.list.findIndex((el)=>el.id === id)
    state.mailSent.list.splice(index, 1);
  },
}
