export default {
  userTypes: {
    'super_administrator_sys': 'Super Administrateur',
    'administrator_sys': 'Administrateur',
    'gestionnaire_sys': "Gestionnaire",
    'medical_advisor_sys': "Médecin-Conseil",
    'none': "Aucun",
  },
  brokerOwn: {},
  allVariableList: [
    { id: "{{Nom}}", label: "Nom" },
    { id: "{{Prénom}}", label: "Prénom" },
    { id: "{{Civilité}}", label: "Civilité" },
    { id: "{{Date_Souscription}}", label: "Date Souscription" },
    { id: "{{Date_Effet}}", label: "Date Effet" },
    { id: "{{Date_Echéance}}", label: "Date Echéance" },
    { id: "{{Produit}}", label: "Produit" },
    { id: "{{Lien_Simulation}}", label: "Lien Simulation" },
    { id: "{{Lien_Fiche_Produit}}", label: "Lien Fiche Produit" },
    { id: "{{Ref_Projet}}", label: "Référence Projet" },
    { id: "{{Ref_Contrat}}", label: "Référence contrat" },
    { id: "{{Nom_Intermédiaire}}", label: "Nom Intermédiaire" },
    { id: "{{Prénom_Intermédiaire}}", label: "Prénom Intermédiaire" },
    { id: "{{Société_Intermédiaire}}", label: "Société Intermédiaire" },
  ]

}
